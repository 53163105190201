import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getProfileUser } from '../../services/MyProfile'
import { TAGS } from '../../services/Tags'
import { IS_COMPANY, OUT_OF_THE_APP } from '../../utils/constants'
import LongWaitTimeBanner from './components/LongWaitTimeBanner'
import WaitingCreateFirstOffer from './components/WaitingCreateFirstOffer'
import { CALENDLY_API_URL } from '../../services/constants'

const ScheduleCallBanner = () => {
  const { data: profileUserData = {} } = useQuery(
    [`${TAGS.profile_data}-schedule-call-banner`],
    getProfileUser,
    {
      enabled: !OUT_OF_THE_APP && IS_COMPANY,
    }
  )

  const { userInfo = {} } = profileUserData ?? {}
  const { validated_account = false, first_offer_created = false } =
    userInfo ?? {}

  if (!IS_COMPANY) {
    return <></>
  }

  return (
    <div className="mt-3 mb-3">
      <Link to={CALENDLY_API_URL} target="_blank" className="cursor-pointer">
        {first_offer_created ? (
          validated_account ? (
            <></>
          ) : (
            <LongWaitTimeBanner />
          )
        ) : (
          <WaitingCreateFirstOffer />
        )}
      </Link>
    </div>
  )
}

export default ScheduleCallBanner
