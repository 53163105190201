const ArrowLeftIcon = ({ width = '25', height = '25', fill = '#004949' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_614_30903"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.5" y="0.456787" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_614_30903)">
        <path
          d="M14.5 18.4568L8.5 12.4568L14.5 6.45679L15.9 7.85679L11.3 12.4568L15.9 17.0568L14.5 18.4568Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default ArrowLeftIcon
