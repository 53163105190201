const SmallBriefcaseIcon = ({ width = '24', height = '25' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2828_7371"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.772949" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2828_7371)">
      <path
        d="M4 21.7729C3.45 21.7729 2.97917 21.5771 2.5875 21.1854C2.19583 20.7938 2 20.3229 2 19.7729V8.77295C2 8.22295 2.19583 7.75212 2.5875 7.36045C2.97917 6.96878 3.45 6.77295 4 6.77295H8V4.77295C8 4.22295 8.19583 3.75212 8.5875 3.36045C8.97917 2.96878 9.45 2.77295 10 2.77295H14C14.55 2.77295 15.0208 2.96878 15.4125 3.36045C15.8042 3.75212 16 4.22295 16 4.77295V6.77295H20C20.55 6.77295 21.0208 6.96878 21.4125 7.36045C21.8042 7.75212 22 8.22295 22 8.77295V19.7729C22 20.3229 21.8042 20.7938 21.4125 21.1854C21.0208 21.5771 20.55 21.7729 20 21.7729H4ZM4 19.7729H20V8.77295H4V19.7729ZM10 6.77295H14V4.77295H10V6.77295Z"
        fill="#E4F8ED"
      />
    </g>
  </svg>
)
export default SmallBriefcaseIcon
