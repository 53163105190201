const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
}

const Button = ({
  id = '',
  className = 'btn-bg-CTA-primary text-on-bg-primary',
  children,
  isSmall = false,
  variant = 'primary',
  ...rest
}) => {
  //TODO: change this form to render this styles
  const primaryStyles = className
  const secondaryStyles =
    'btn-bg-primary border border-on-bg-primary text-on-bg-primary'

  const renderVariants = {
    [VARIANTS.primary]: primaryStyles,
    [VARIANTS.secondary]: secondaryStyles,
  }

  return (
    <button
      id={id}
      className={`btn ${isSmall ? 'btn-sm' : ''} ${renderVariants[variant]}`}
      type="submit"
      {...rest}
      style={{
        borderRadius: '8px',
      }}
    >
      {children}
    </button>
  )
}

export default Button
