import { useTranslation } from 'react-i18next'
import InnerBanner from '../../../components/InnerBanner'
import DashboardWrapper from '../../Dashboard/components/DashboardWrapper'
import Badge from '../../../components/Badge/Badge'
import CountMaster from '../../../components/Count/CountMaster'
import Eye from '../../../icons/Eye'
import HeartIcon from '../../../icons/HeartIcon'
import LocationIcon from '../../../icons/LocationIcon'
import BriefcaseOutlineIcon from '../../../icons/BriefcaseOutlineIcon'
import CalendarOutlineIcon from '../../../icons/CalendarOutlineIcon'
import Divider from '../../../components/Divider'
import MagnifyingGlass from '../../../icons/MagnifyingGlass'
import StarTwoIcon from '../../../icons/StarTwoIcon'
import RocketIcon from '../../../icons/RocketIcon'
import { useQuery } from '@tanstack/react-query'
import { TAGS } from '../../../services/Tags'
import {
  getCompanyInformation,
  getListOfOrderCompanies,
} from '../../../services/CompanyData'
import { useNavigate, useParams } from 'react-router-dom'
import CompanyDetailSkeleton from '../../../skeleton/Company/CompanyDetailSkeleton'
import Circle from '../../../components/Circle'
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon'
import {
  IS_ADMIN,
  IS_HIRING_ADVISOR,
  ITEMS_PER_PAGE,
  VIEWPORT_LG,
} from '../../../utils/constants'
import useResize from '../../../hooks/useResize'
import ArrowRigthIcon from '../../../icons/ArrowRigthIcon'
import Toast from '../../../components/Toast/Toast'
import { useAdminCompaniesStore } from '../../../store'
import error_load_icon from '../../../assets/error_load_icon.png'
import dayjs from 'dayjs'
import { PRIVATE_ROUTES } from '../../../Routes/routes'
import EmptyState from '../../../components/EmptyState'
import detail_company_empty_state from '../../../assets/detail_company_empty_state.png'
import { listOfCompanies } from '../../../services/Admin'
import Button from '../../../components/Button'

const CompanyDetail = ({ seeFromHA }) => {
  const { page, setPage, search, tabBarValue } = useAdminCompaniesStore()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const viewportSize = useResize()
  const {
    data: companyInformationData = [],
    isInitialLoading: isLoadingCompanyDetailFromAdmin,
  } = useQuery([TAGS.company_detail, id], () => getCompanyInformation(id), {
    enabled: id !== undefined,
  })

  const {
    data: listOfCompaniesData = [],
    isInitialLoading: isLoadingListOfCompanies,
  } = useQuery([TAGS.list_of_companies, page, search, tabBarValue], () =>
    listOfCompanies({
      page,
      searchByText: search,
      companiesAuthorized: tabBarValue,
    })
  )

  const {
    name = '',
    company_sector = {},
    logo = '',
    address = {},
    review = '',
    mision = '',
    vision = '',
    outstanding = '',
    range_worker = '',
    range_year = '',
    create_date = '',
    count_offers = 0,
    count_offers_sent = 0,
    count_hired = 0,
  } = companyInformationData ?? []
  const today = dayjs().format('DD-MM-YYYY')
  const transformCreateDay = dayjs(create_date)?.format('DD-MM-YYYY')
  const isSameDate = transformCreateDay === today

  const handleNextCompany = async () => {
    try {
      const totalPages = Math.ceil(listOfCompaniesData?.count / ITEMS_PER_PAGE)

      const currentIndex = listOfCompaniesData?.list?.findIndex(
        (company) => Number(company.id) === Number(id)
      )
      const nextIndex = currentIndex + 1
      const lastItem = listOfCompaniesData?.list?.length - 1
      const lastPage = totalPages - 1

      const isLastItem = Number(currentIndex) === Number(lastItem)
      const isLastPage = page === lastPage

      if (isLastItem && !isLastPage) {
        await setPage(page + 1)
        const newCompanyData = await getListOfOrderCompanies({
          page: page + 1,
          searchText: search,
          companiesAuthorized: true,
        })

        const renderNewCompnayData = newCompanyData?.list

        await navigate(`/company-detail/${renderNewCompnayData?.[0]?.id}`)
        return
      }

      if (isLastPage && isLastItem) {
        Toast({
          title: 'No hay más empresas',
          icon: 'info',
        })
        return
      }

      navigate(`/company-detail/${listOfCompaniesData?.list?.[nextIndex]?.id}`)
    } catch (error) {
      console.error('Error navigating to next talent:', error)
    }
  }

  const handlePreviousCompany = async () => {
    try {
      const currentIndex = listOfCompaniesData?.list?.findIndex(
        (company) => Number(company.id) === Number(id)
      )
      const previousIndex = currentIndex - 1
      const initialItem = 0
      const initialPage = 0

      const isInitialItem = Number(currentIndex) === Number(initialItem)
      const isInitialPage = page === initialPage

      if (isInitialItem && !isInitialPage) {
        await setPage(page - 1)
        const newCompanyData = await getListOfOrderCompanies({
          page: page - 1,
          searchText: search,
          companiesAuthorized: true,
        })

        const renderCompanyData = newCompanyData?.list ?? []

        await navigate(`/company-detail/${renderCompanyData?.[9]?.id}`)
        return
      }

      if (isInitialPage && isInitialItem) {
        Toast({
          title: 'No hay más empresas',
          icon: 'info',
        })
        return
      }

      navigate(
        `/company-detail/${listOfCompaniesData?.list?.[previousIndex]?.id}`
      )
    } catch (error) {
      console.error('Error navigating to next talent:', error)
    }
  }

  return (
    <DashboardWrapper dashboardWrapperClassName="w-100">
      {viewportSize[0] >= VIEWPORT_LG && !seeFromHA && (
        <>
          <div
            className="position-absolute start-0 cursor-pointer"
            style={{
              marginLeft: '-60px',
              marginTop: '250px',
            }}
            onClick={handlePreviousCompany}
          >
            <Circle className="bg-white border border-on-bg-Tertiary p-4">
              <ArrowLeftIcon width="32" height="32" />
            </Circle>
          </div>
          <div
            className="position-absolute start-100 cursor-pointer ms-2"
            style={{
              marginTop: '250px',
            }}
            onClick={handleNextCompany}
          >
            <Circle className="bg-white border border-on-bg-Tertiary p-4">
              <ArrowRigthIcon width="32" height="32" />
            </Circle>
          </div>
        </>
      )}
      <div className="mt-5">
        <InnerBanner
          beforeRoute={IS_ADMIN ? PRIVATE_ROUTES.admin_companies : -1}
          before={IS_HIRING_ADVISOR ? t('my_enterprise') : t('companies')}
          text={t('company_details')}
        />

        {isLoadingCompanyDetailFromAdmin || isLoadingListOfCompanies ? (
          <CompanyDetailSkeleton />
        ) : (
          <div className="p-5 rounded border border-bg-blue bg-bg-blue-opacity mb-4 mt-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-start gap-3">
                <img
                  src={logo ?? error_load_icon}
                  width="64px"
                  height="64px"
                  className="rounded rounded-pill object-fit-cover shadow-sm"
                />
                <div>
                  <span className="fs-3 text-bg-blue fw-bolder">
                    {name ?? t('not_available')}
                  </span>
                  <div className="d-flex align-items-center gap-2">
                    <span className="text-on-bg-primary">
                      {company_sector?.name ?? t('not_available')}
                    </span>
                    {isSameDate && (
                      <Badge className="border border-on-bg-Success bg-bg-Success text-on-bg-Success">
                        {t('new')}
                      </Badge>
                    )}
                  </div>
                </div>
              </div>
              {IS_HIRING_ADVISOR && seeFromHA && (
                <div>
                  <Button
                    onClick={() => {
                      navigate(`/list-of-offers/${id}`)
                    }}
                  >
                    Ver ofertas
                  </Button>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center gap-3 mt-4">
              <CountMaster
                backgroundColor="bg-bg-blue-opacity"
                Icon={() => <Eye width="32px" height="32px" fill="#407BFF" />}
                title={t('total_offers')}
                isFullWidth
                count={count_offers ?? 0}
              />
              <CountMaster
                backgroundColor="bg-bg-blue-opacity"
                Icon={() => <HeartIcon fill="#407BFF" />}
                title={t('offers_sent')}
                isFullWidth
                count={count_offers_sent ?? 0}
              />
              <CountMaster
                backgroundColor="bg-bg-blue-opacity"
                Icon={() => <Eye width="32px" height="32px" fill="#407BFF" />}
                title={t('hired')}
                isFullWidth
                count={count_hired ?? 0}
              />
            </div>
            {logo ? (
              <>
                <div className="rounded bg-white mt-4 d-flex flex-column justify-content-center align-items-center p-3">
                  <img
                    src={logo ?? error_load_icon}
                    width="64px"
                    height="64px"
                    className="rounded rounded-pill object-fit-cover shadow-sm"
                  />
                  <span className="text-on-bg-primary fw-bolder fs-4 mt-2">
                    {name ?? t('not_available')}
                  </span>
                  <span className="text-on-bg-secondary font-small-2">
                    {company_sector?.name ?? t('not_available')}
                  </span>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-3 font-small">
                    <div className="d-flex align-items-center gap-1 text-on-bg-primary">
                      <LocationIcon />
                      {address?.city?.city_name ?? ''}{' '}
                      {address?.country?.country_name ?? t('not_available')}
                    </div>
                    <div className="d-flex align-items-center gap-1 text-on-bg-primary">
                      <BriefcaseOutlineIcon />
                      <span>
                        {range_worker
                          ? `${range_worker?.range} ${t('workers')}`
                          : t('not_available')}
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-1 text-on-bg-primary">
                      <CalendarOutlineIcon />
                      <span>{range_year?.years ?? t('not_available')}</span>
                    </div>
                  </div>
                  <Divider className="w-100 bg-bg-brand" />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: review ?? '',
                    }}
                    className="text-on-bg-secondary text-center mt-2"
                  />
                </div>
                {}{' '}
                <div className="d-flex flex-lg-row flex-column justify-content-center align-items-center gap-lg-4 gap-0 mt-lg-4 mt-0">
                  {mision && (
                    <div className="bg-white p-4 rounded mision-w">
                      <div className="d-flex flex-row flex-lg-column justify-content-lg-center  align-items-center gap-2">
                        <MagnifyingGlass fill="#407BFF" bgFill="#f1f4fa" />

                        <h3 className="text-on-bg-primary fw-bolder ">
                          {t('mission')}
                        </h3>
                        <span
                          className="text-on-bg-secondary overflow-auto"
                          style={{
                            wordWrap: 'break-word',
                            width: '300px',
                            height: '350px',
                            overflow: 'auto',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: mision ?? '',
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {vision && (
                    <div className="bg-white p-4 rounded vision-w">
                      <div className="d-flex flex-row flex-lg-column justify-content-lg-center align-items-center gap-2">
                        <RocketIcon fill="#407BFF" bgFill="#f1f4fa" />
                        <h3 className="text-on-bg-primary fw-bolder">
                          {t('vision')}
                        </h3>
                        <span
                          style={{
                            wordWrap: 'break-word',
                            width: '300px',
                            height: '350px',
                            overflow: 'auto',
                          }}
                          className="text-on-bg-secondary"
                          dangerouslySetInnerHTML={{
                            __html: vision ?? '',
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {outstanding && (
                  <div className="bg-white mt-lg-3 mt-0 p-4 mb-3 rounded">
                    <div className="d-flex align-items-center gap-2">
                      <StarTwoIcon fill="#407BFF" bgFill="#f1f4fa" />
                      <h3 className="text-on-bg-primary inline-text fw-bolder">
                        {t('highlights')}
                      </h3>
                    </div>
                    <span
                      className="text-on-bg-secondary"
                      dangerouslySetInnerHTML={{
                        __html: outstanding ?? '',
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <EmptyState
                showButton={false}
                src={detail_company_empty_state}
                imgWidth={179.48}
                imgHeight={178.18}
                title={t('this_company_has_not_completed_its_profile')}
                description={t(
                  'you_will_be_able_to_review_and_activate_this_company_once_you_have_completed_your_profile'
                )}
              />
            )}
          </div>
        )}
      </div>
    </DashboardWrapper>
  )
}

export default CompanyDetail
