const MessageStarIcon = ({width = 33, height = 33, fill = '#004949' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_294_7204"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="33"
        height="33"
      >
        <rect x="0.112305" y="0.78125" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_294_7204)">
        <path
          d="M2.7793 30.1146V6.1146C2.7793 5.38127 3.04041 4.75349 3.56263 4.23127C4.08485 3.70905 4.71263 3.44794 5.44596 3.44794H26.7793C27.5126 3.44794 28.1404 3.70905 28.6626 4.23127C29.1849 4.75349 29.446 5.38127 29.446 6.1146V22.1146C29.446 22.8479 29.1849 23.4757 28.6626 23.9979C28.1404 24.5202 27.5126 24.7813 26.7793 24.7813H8.11263L2.7793 30.1146ZM5.44596 23.6813L7.01263 22.1146H26.7793V6.1146H5.44596V23.6813ZM12.2126 19.7813L16.1126 17.4146L20.0126 19.7813L18.9793 15.3479L22.446 12.3479L17.8793 11.9813L16.1126 7.78127L14.346 11.9813L9.7793 12.3479L13.246 15.3479L12.2126 19.7813Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default MessageStarIcon
