import SearchIcon from '../icons/SearchIcon'

const SearchInput = ({ className, children, ...rest }) => {
  return (
    <div className="d-flex align-items-center position-relative">
      <div className="position-absolute ms-2 text-on-bg-secondary mb-1">
        <SearchIcon />
      </div>
      <input className={`form-control ps-4 ${className}`} {...rest}>
        {children}
      </input>
    </div>
  )
}

export default SearchInput
