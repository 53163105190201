const CashSmallIcon = ({ width = '13', height = '13', fill = '#004949' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
    >
      <mask
        id="mask0_5255_8591"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="13"
        height="13"
      >
        <rect x="0.921875" y="0.5" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5255_8591)">
        <path
          d="M7.92188 7C7.50521 7 7.15104 6.85417 6.85938 6.5625C6.56771 6.27083 6.42188 5.91667 6.42188 5.5C6.42188 5.08333 6.56771 4.72917 6.85938 4.4375C7.15104 4.14583 7.50521 4 7.92188 4C8.33854 4 8.69271 4.14583 8.98438 4.4375C9.27604 4.72917 9.42188 5.08333 9.42188 5.5C9.42188 5.91667 9.27604 6.27083 8.98438 6.5625C8.69271 6.85417 8.33854 7 7.92188 7ZM4.42188 8.5C4.14687 8.5 3.91146 8.40208 3.71563 8.20625C3.51979 8.01042 3.42188 7.775 3.42188 7.5V3.5C3.42188 3.225 3.51979 2.98958 3.71563 2.79375C3.91146 2.59792 4.14687 2.5 4.42188 2.5H11.4219C11.6969 2.5 11.9323 2.59792 12.1281 2.79375C12.324 2.98958 12.4219 3.225 12.4219 3.5V7.5C12.4219 7.775 12.324 8.01042 12.1281 8.20625C11.9323 8.40208 11.6969 8.5 11.4219 8.5H4.42188ZM5.42188 7.5H10.4219C10.4219 7.225 10.5198 6.98958 10.7156 6.79375C10.9115 6.59792 11.1469 6.5 11.4219 6.5V4.5C11.1469 4.5 10.9115 4.40208 10.7156 4.20625C10.5198 4.01042 10.4219 3.775 10.4219 3.5H5.42188C5.42188 3.775 5.32396 4.01042 5.12812 4.20625C4.93229 4.40208 4.69688 4.5 4.42188 4.5V6.5C4.69688 6.5 4.93229 6.59792 5.12812 6.79375C5.32396 6.98958 5.42188 7.225 5.42188 7.5ZM10.9219 10.5H2.42188C2.14688 10.5 1.91146 10.4021 1.71563 10.2063C1.51979 10.0104 1.42188 9.775 1.42188 9.5V4H2.42188V9.5H10.9219V10.5Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default CashSmallIcon
