import dayjs from 'dayjs'
import { BASE_URL } from '../constants'
const token = JSON.parse(localStorage.getItem('token'))

export const getAllTalentsFromAdmin = ({
  page = 0,
  profileStatus = 0,
  hasManager = 0,
  dateBefore = '2024-01-03',
  dateAfter = '2024-04-03',
  country = '',
  textToSearch = '',
  orderBy = 'desc',
  toLookFor = 1,
}) => {
  const URL = `${BASE_URL}master/getListTalents?profileStatus=${profileStatus}&hasManager=${hasManager}&dates={"before":"${dateBefore}","after":"${dateAfter}","toLookFor":${toLookFor}}&country=${country}&textToSearch=${textToSearch}&orderBy=${orderBy}&page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.container)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const activeTalentFromAdmin = (data) => {
  const URL = `${BASE_URL}/masterActiveTalent`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getAllRecruitersFromAdmin = ({
  page = 0,
  stateAccount = '',
  textToSearch = '',
  stateActiveAccount = true,
}) => {
  const URL = `${BASE_URL}master/getListRecruiters?page=${page}&stateAccount=${stateAccount}&textToSearch=${textToSearch}&stateActiveAccount=${stateActiveAccount}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfRecruiters = () => {
  const URL = `${BASE_URL}master/getListRecruiters`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.listRecruiters || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getRecruiterDetailFromAdmin = (id) => {
  const URL = `${BASE_URL}/getprofilerecruiter/${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.profileUser)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getCompleteOrIncompleteTalentsFromAdminData = ({
  id = 0,
  page = 0,
  username = '',
  email = '',
  activate = false,
}) => {
  const URL = `${BASE_URL}/mastergetredrecruiter/${id}?page=${page}&searchByUsername=${username}&searchByEmail=${email}&talentsActive=${activate}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getAnalytics = () => {
  const URL = `${BASE_URL}/master-hutrit-analytics`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.info)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getCompaniesFromAdmin = ({ page = 0 }) => {
  const URL = `${BASE_URL}mastergetcompanies/${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const activeRecruiterOrCompanyFromAdmin = ({
  typeOfUser = '',
  state = true,
  id = '',
}) => {
  const URL = `${BASE_URL}master/setValidateAccount`

  const rawBody = JSON.stringify({
    id,
    state,
    typeProfile: typeOfUser,
  })

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const sendFeedbackFromMaster = ({ typeOfUser = '', id }) => {
  const URL = `${BASE_URL}master-sendmail/${typeOfUser}-${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const changeManager = (data) => {
  const URL = `${BASE_URL}master/change-manager`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfTalentFromRecruiter = async ({
  page = 0,
  idRecruiter = 0,
  typeFilter = '',
  categoriJob = [],
  dateBefore = '',
  dateAfter = '',
  searchByUsername = '',
  searchByEmail = '',
}) => {
  const URL = `${BASE_URL}master/inforedmanager/${page}?searchByUsername=${searchByUsername}&searchByEmail=${searchByEmail}`

  const validateDateAfter = dayjs(dateBefore).isSame(dayjs(dateAfter))
    ? dayjs(dateAfter).add(1, 'day').format('YYYY-MM-DD')
    : dateAfter

  const rawBody = JSON.stringify({
    idRecruiter: Number(idRecruiter),
    typeFilter,
    categoriJob,
    dateBefore,
    dateAfter: validateDateAfter,
  })

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    const listOfTalents = await response.json()

    return {
      listOfTalents,
      response,
    }
  } catch (error) {
    console.log(error)
  }
}

export const sendEmails = async ({
  subject = '',
  textPreView = '',
  text = '',
  centerText = '',
  listEmailTarget = [],
  idImg,
  titleBtn,
  defaultUrlBtn,
  urlPersonalized,
}) => {
  const URL = `${BASE_URL}master/sendMail?target=personalized`

  const rawBody = JSON.stringify({
    subject: subject,
    textPreView,
    text,
    listEmailTarget,
    centerText,
    idImg,
    configBtn: {
      renderBtn: true,
      titleBtn,
      defaultUrlBtn,
      urlPersonalized,
    },
  })

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getEmails = ({
  typeUser = '',
  userName = '',
  validateAccount = 0,
  activeManager = 0,
}) => {
  const URL = `${BASE_URL}master/getmails?typeUser=${typeUser}&searchUsername=${userName}&searchActiveManager=${validateAccount}&searchValidateAccoun=${activeManager}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getAllSkills = ({ page = 0, searchForName = '' }) => {
  const URL = `${BASE_URL}master/getskillsDB?search=${searchForName}&page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.content)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createSkill = (data) => {
  const URL = `${BASE_URL}master/addskillsDB`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const hideSkill = ({ id, state, name }) => {
  const URL = `${BASE_URL}master/setstate-skillsDB`

  const rawBody = JSON.stringify({
    idSkill: id,
    name,
    state,
  })

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getSoftSkills = ({ searchForName = '', page = 0 }) => {
  const URL = `${BASE_URL}master/listSoftSkills?page=${page}&search=${searchForName}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createSoftSkill = (data) => {
  const URL = `${BASE_URL}master/softSkills/create`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const editSoftSkills = (data) => {
  const URL = `${BASE_URL}master/softSkills/update`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getAllCategories = ({ page = 0, searchForName = '' }) => {
  const URL = `${BASE_URL}master/getcategoryjobsDB?search=${searchForName}&page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.content)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createCategory = (data) => {
  const URL = `${BASE_URL}master/addcategoryjobsDB`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const hideCategory = ({ id, state, name }) => {
  const URL = `${BASE_URL}master/setstate-categoryjobsDB`
  const rawBody = JSON.stringify({ idCategory: id, state, name })

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getAllSectors = ({ page = 0, searchForName = '' }) => {
  const URL = `${BASE_URL}master/getsectorsDB?search=${searchForName}&page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.content)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createSector = (data) => {
  const URL = `${BASE_URL}master/addsectorsDB`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const hideSector = ({ id, state }) => {
  const URL = `${BASE_URL}master/setstate-sectorsDB`

  const rawBody = JSON.stringify({ list: [{ id, state }] })

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createCity = (data) => {
  const URL = `${BASE_URL}master/add-city`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getlanguages = () => {
  const URL = `${BASE_URL}getlanguajes`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.languajes)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createLanguage = (data) => {
  const URL = `${BASE_URL}master/add-lang`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getListOfFastOffers = ({ page = 0 }) => {
  const URL = `${BASE_URL}order/getOffers?page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getListOfValidateFastOffers = ({ page = 0 }) => {
  const URL = `${BASE_URL}order/getApprovedOffers?page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getFastOfferDetail = ({ id }) => {
  const URL = `${BASE_URL}order/getOneOffer?id=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.offerOrder)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const allowAccessCompany = (data) => {
  const URL = `${BASE_URL}master/activatedQuickCompanies`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const allowAccessJobOffer = (data) => {
  const URL = `${BASE_URL}order/approvedOffer`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const sendValidateOffer = (data) => {
  const URL = `${BASE_URL}order/sendOffer`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getValidateOfferDetail = ({ id }) => {
  const URL = `${BASE_URL}order/getOneApprovedOffer?id=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.offer)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getFavoriteOfferByAdmin = ({ page = 0, id = 0 }) => {
  const URL = `${BASE_URL}order/getFavoriteOffer?page=${page}&idOffer=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const addFavoriteByAdmin = (data) => {
  const URL = `${BASE_URL}order/addFavorite`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getStatesSendOfferByAdmin = ({
  page = 0,
  id = 0,
  typeFilter = 0,
}) => {
  const URL = `${BASE_URL}/order/getStatesSendOffer?idOffer=${id}&page=${page}&typeFilter=${typeFilter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.statesSendOffers)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const removeFavoriteByAdmin = ({ id = 0 }) => {
  const URL = `${BASE_URL}order/removeFavorite?idfavorite=${id}`

  try {
    const response = fetch(URL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const desactiveJobOffer = (data) => {
  const URL = `${BASE_URL}order/desactiveOffer`
  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getTalentsInformation = ({ ids }) => {
  const URL = `${BASE_URL}master/getAllProfilesTalents?talentsId=${ids}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.talentsInfo)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createState = (data) => {
  const URL = `${BASE_URL}master/add-state`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const deleteUser = ({ id = 0, username = '' }) => {
  const URL = `${BASE_URL}master/destroy-user?idUser=${id}&username=${username}`

  try {
    const response = fetch(URL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createHiringAdvisor = (data) => {
  const URL = `${BASE_URL}master/createHiringAdvisor`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getHiringAdvisorList = ({ page }) => {
  const URL = `${BASE_URL}master/getHiringAdvisor?page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const assignCompany = (data) => {
  const URL = `${BASE_URL}/master/assignCopmaniesHiringAdvisor`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const desactiveUser = (data) => {
  const URL = `${BASE_URL}master/setStateAccount`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const editSkill = (data) => {
  const URL = `${BASE_URL}master/updateNameSkill`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const changeAllTalents = (data) => {
  const URL = `${BASE_URL}master/change-all-manager`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const profileHiringAdvisor = ({ id }) => {
  const URL = `${BASE_URL}master/getProfileHiringAdvisor?idHiringAdvisor=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listCompaniesHa = ({ id, page }) => {
  const URL = `${BASE_URL}master/getListCompanies?page=${page}&idHiringAdvisor=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOffersOrderHa = ({ id, page }) => {
  const URL = `${BASE_URL}master/getListOffersOrder?page=${page}&idHiringAdvisor=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const activateTalent = (data) => {
  const URL = `${BASE_URL}master/validationAccountHutrit`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfQuickOffers = ({ page }) => {
  const URL = `${BASE_URL}master/getAllFasterOffers?page=${
    page ?? 0
  }&expressOfferStatus`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const changeQuickOfferStatus = ({ id, state }) => {
  const URL = `${BASE_URL}master/setStateReviewed`

  const rawBody = JSON.stringify({
    id,
    newState: state,
  })

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfRankingTopThreeRecruiters = ({ dateBefore, dateAfter }) => {
  const URL = `${BASE_URL}master/rankingTopThreeRecruiters?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.infoTopThree)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfRankingRecruiters = ({
  page = 0,
  dateBefore = '',
  dateAfter = '',
  typeOrder = 'registered',
  searchUsername = '',
}) => {
  const URL = `${BASE_URL}master/listRankingTopRecruiters?page=${page}&dateBefore=${dateBefore}&dateAfter=${dateAfter}&typeOrder=${typeOrder}&searchUsername=${searchUsername}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.listTopRecruiters)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const talentManagerDetail = ({
  id = 0,
  dateBefore = '',
  dateAfter = '',
  typeOrder = 'registered',
}) => {
  const URL = `${BASE_URL}master/listTalentsByIdRecruiter?idRecruiter=${id}&dateBefore=${dateBefore}&dateAfter=${dateAfter}&typeFilter=${typeOrder}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.listTalents)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const countTalentsRegistered = ({ dateBefore = '', dateAfter = '' }) => {
  const URL = `${BASE_URL}master/kpi/countTalentsRegistered?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.count)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const countTalentsActive = ({ dateBefore = '', dateAfter = '' }) => {
  const URL = `${BASE_URL}master/kpi/countTalentsActives?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.metrict)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const percetagesTalentsRegisteredAndActives = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/percetagesTalentsRegisteredAndActives?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.count)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const talentsManagersRegistered = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/countRecruitersRegistered?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.count)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const talentsWithCompleteProfile = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/countTalentsWthCompleteProfile?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.metrict)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const graphCountConnections = ({ dateBefore = '', dateAfter = '' }) => {
  const URL = `${BASE_URL}master/kpi/graphCountConnections?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.connectionns)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const graphCountRegisteredTalents = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/graphCountRegisteredTalents?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.values)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const graphCountValidatedTalents = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/graphCountValidatedTalents?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.values)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const graphCountRegisteredRecruiters = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/graphCountRegisteredRecruiters?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.values)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const graphCountActivesRecruiters = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/graphCountActivesRecruiters?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.values)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const countTalentsActivated = ({ dateBefore = '', dateAfter = '' }) => {
  // master/kpi/countTalentsActives?dateBefore=2023-03-01&dateAfter=2023-08-29

  const URL = `${BASE_URL}master/kpi/countTalentsActives?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.metrict)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const dashboardMetrictTalents = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/dashboardMetrictTalents?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const dashboardMetrictRecruiters = ({
  dateBefore = '',
  dateAfter = '',
}) => {
  const URL = `${BASE_URL}master/kpi/dashboardMetrictRecruiters?dateBefore=${dateBefore}&dateAfter=${dateAfter}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.metrict || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const counterTalents = () => {
  const URL = `${BASE_URL}master/counterTalents`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const recruitersConnectionList = ({
  dateBefore = '',
  dateAfter = '',
  searchTm = '',
  page,
}) => {
  const URL = `${BASE_URL}master/kpi/recruitersConnectionList?dateBefore=${dateBefore}&dateAfter=${dateAfter}&searchText=${searchTm}&orderByConnectionCount=desc&page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const visibleOffer = (data) => {
  const URL = `${BASE_URL}master/v2/setVisibleOffer`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updateInterviewStatus = (data) => {
  const URL = `${BASE_URL}master/companies/setStatus`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const changeStateActiveCompany = (data) => {
  const URL = `${BASE_URL}master/changeStateActive`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const removeQuickCompany = ({ id = 0 }) => {
  const URL = `${BASE_URL}master/deleteQuickCompany?quickCompanyId=${id}`

  try {
    const response = fetch(URL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const changeRecruiter = (data) => {
  const URL = `${BASE_URL}master/changeRecruiter`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createNoteQuickCompanies = (data) => {
  const URL = `${BASE_URL}notes/quickCompanies/create`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updateNoteQuickCompanies = (data) => {
  const URL = `${BASE_URL}notes/quickCompanies/update`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listQuickCompaniesNotes = ({ page = 0, quickCompanyId = 0 }) => {
  const URL = `${BASE_URL}notes/quickCompanies/list?page=${
    page ?? 0
  }&quickCompanyId=${quickCompanyId ?? 0}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getOneQuickCompany = ({ quickCompanyId = 0 }) => {
  const URL = `${BASE_URL}notes/quickCompanies/getOne?quickCompanyId=${quickCompanyId}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createNoteByActiveCompany = (data) => {
  const URL = `${BASE_URL}notes/user/create`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getOneNoteByActiveCompany = ({ quickCompanyId = 0 }) => {
  const URL = `${BASE_URL}notes/user/getOne?userId=${quickCompanyId}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updateNoteByActiveCompany = (data) => {
  const URL = `${BASE_URL}notes/user/update`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const deleteCompany = (data) => {
  const URL = `${BASE_URL}master/deleteUser`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfCompanies = ({
  page = 0,
  searchByText = '',
  companiesAuthorized = '',
  subcription = '',
}) => {
  const URL = `${BASE_URL}master/listCompanies?page=${page}&searchByText=${searchByText}&companiesAuthorized=${companiesAuthorized}&subcription=${subcription}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.companies || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfAccountantsCompanies = () => {
  const URL = `${BASE_URL}master/accountantsCompanies`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const activeTalentWithFeedback = (data) => {
  const URL = `${BASE_URL}master/generalActivationTalentAccount`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const cronJobMatchOffers = (data) => {
  const URL = `${BASE_URL}test/cronJobMatchOffers`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const deleteRecruiterFromAdmin = (data) => {
  const URL = `${BASE_URL}master/deactivateRecruiter`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getSingleSuscription = ({ typePlan = 1 }) => {
  const URL = `${BASE_URL}master/subcriptionPlans/getOneInfoTechnical?subcriptionPlanId=${
    typePlan ?? 1
  }`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getSuscriptionsCounts = () => {
  const URL = `${BASE_URL}master/subcriptionPlans/countTotalSubcriptionsActives`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updatePlan = (data) => {
  const URL = `${BASE_URL}master/subcriptionPlans/updateFeaturesTechnical`
  const sendNameValue = {
    1: 'Gratuito',
    2: 'Plan básico',
    3: 'Plan Pro',
    4: 'Plan Premium',
  }
  const rawBody = JSON.stringify({
    id: data?.profileId,
    name: sendNameValue[data?.profileId],
    price: data?.price,
    days: data?.days,
    coin_id: 2,
    active: true,
    featuresList: [
      {
        id: data?.allIds.totalUsers,
        maximumAmount: Number(data?.totalUsers),
        allowed: true,
        limitedQuantity: data?.profileId === 4 ? false : true,
      },
      {
        id: data?.allIds.sendOffers,
        maximumAmount: Number(data?.sendOffers),
        allowed: true,
        limitedQuantity: data?.profileId === 4 ? false : true,
      },
      {
        id: data?.allIds.profileValidates,
        maximumAmount: Number(data?.profileValidates),
        allowed: true,
        limitedQuantity: data?.profileId === 4 ? false : true,
      },
      {
        id: data?.allIds.activeOffers,
        maximumAmount: Number(data?.activeOffers),
        allowed: true,
        limitedQuantity: data?.profileId === 4 ? false : true,
      },
      {
        id: data?.allIds.days,
        maximumAmount: Number(data?.days),
        allowed: true,
        limitedQuantity: data?.profileId === 4 ? false : true,
      },
    ],
    coin: {
      id: 2,
      img: null,
      symbol: '€',
      name: 'Euro',
    },
  })

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const assignSuscription = (data) => {
  const URL = `${BASE_URL}master/subcriptionPlans/assign`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getActiveSubscriptionByUserId = ({ userId = null }) => {
  const URL = `${BASE_URL}/master/subcription/getActiveSubscriptionByUserId?userId=${userId}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}



